import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { get, patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ['notificationBell',
                    'notificationsBlock',
                    'notificationCount',
                    'notificationItems',
                    'notificationBellSvg',
                    'notificationsList',
                    'readAllNotification',
                    'checkStatus',
                    'titlePane',
                    'nonePane',
                    'link'
                  ]

  initialize() {
    this.originalTitle = document.querySelector('head > title').innerText // зберігаємо початковий стан 
  }

  connect() {
    // if the clicked element isn't child of the navbar id 'notifications-section', you must close it if is open
    document.addEventListener("click", function (event) {
      if (!event.target.closest("#notifications-section") && document.getElementById("notifications")?.classList.contains("show")) {
          document.getElementById("notification_bell").click()
      }
    })
    // періодично перевіряє чи є непрочитані сповіщення і оновлює вкладку браузера
    const head = document.querySelector('head > title')
    let _this = this // save context
    
    let blinkTitle = () => {
      const unreadCount = _this.notificationCountTarget.dataset.unreadCount
      if (parseInt(unreadCount) > 0) {
        const headTitle = head.innerText
        const localPartName = this.notificationCountTarget.closest('#notification_count').dataset.notifyName
        const countTitle = `+${unreadCount} ${localPartName}`
        head.innerText = headTitle === _this.originalTitle ? countTitle : _this.originalTitle
      } else {
        head.innerText = _this.originalTitle
      }
    }
    this.blinkInterval = setInterval(blinkTitle, 1000)
  }

  disconnected() {
    if (this.blinkInterval) clearInterval(this.blinkInterval)
  }

  notificationItemsTargetConnected(element) {
    // видаляємо title with filters actions якщо немає сповіщень
    if (this.hasNonePaneTarget)  this.nonePaneTarget.classList.add('d-none')
    if (this.hasTitlePaneTarget) this.titlePaneTarget.classList.remove('d-none')
  }

  async clickNotificationBell(event) {   
    const notificationsBlock = this.hasNotificationsBlockTarget && this.notificationsBlockTarget
    this.updateStatusReadAllBtn()
    if (notificationsBlock.style.display === "display") {
      notificationsBlock.style.display = "none"
      return
    }
    event.preventDefault()
    event.stopPropagation()
    await this.getNotifications()
  }
  
  async getNotifications() {
    const url = createRootUrlWithLocale('notifications') // `/${currentLocale()}/notifications`
    const response = await get(url, {
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      // document.querySelector("#notification_count > svg").dataset.prefix = "fas"
      if (this.hasNotificationsBlockTarget) this.notificationsBlockTarget.style.display = "display"
    }
  }

  async getMoreNotifications(event) {
    event.preventDefault()
    event.stopPropagation()
    const url = new URL(createRootUrlWithLocale('notifications')) // const url = this.linkTarget.href
    const searchParams = url.searchParams
    searchParams.append("page", this.linkTarget.dataset.nextPage)
    await get(url, {
      query: {
        endless: 'notifications'
      },
      responseKind: 'turbo-stream'
    })
  }

  async toggle(event) {
    event.preventDefault()
    event.stopPropagation()
    const target = event.target.closest('a')
    const url = new URL(target.href)
    const searchParams = url.searchParams
    searchParams.append("read_id", target.dataset.id)
    const response = await patch(url, {      
     responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.updateStatusReadAllBtn()
    }
  }

  async readAll(event) {
    event.preventDefault()
    event.stopPropagation()
    this.checkStatusTarget.checked = false
    this.checkStatusTarget.disabled = true
    const url = this.readAllNotificationTarget.href
    const response = await patch(url, {
     responseKind: 'turbo-stream'
    })

    if (response.ok) {
      this.disabledReadAllBtn()
    }
  }

  async checkReadOnly(event) {
    event.preventDefault()
    event.stopPropagation()
    const url = new URL(createRootUrlWithLocale('notifications'))
    const checkStatus = this.hasCheckStatusTarget && !this.checkStatusTarget.checked  //this.checkStatusTarget.checked
    if (checkStatus) {
      const searchParams = url.searchParams
      searchParams.append("only_unread", 'true')
    }
    await get(url, {
      responseKind: 'turbo-stream'
    })
  }

  read(event) {
    event.preventDefault()
    event.stopPropagation()
    const notify = event.currentTarget
    const link = notify.tagName === 'A' ? notify : notify.querySelector('a');
    if (event.params.readStatus === false) {
      const url = new URL(event.params.url)
      const searchParams = url.searchParams
      searchParams.append("read_id", event.params.id)
      console.log('URL with search params: ', url)
      patch(url, {
        responseKind: 'turbo-stream'
      })
    }
    if (link.href === 'javascript:void(0);') return
    
    Turbo.visit(link.href)
  }

  disabledReadAllBtn() {
    this.readAllNotificationTarget.classList.add('disabled', 'opacity-50', 'cursor-not-allowed')
  }
  
  updateStatusReadAllBtn() {
    const unreadCount = this.notificationCountTarget.dataset.unreadCount
    if (parseInt(unreadCount) > 0) {
      this.readAllNotificationTarget.classList.remove('disabled', 'opacity-50', 'cursor-not-allowed')
    } else {
      this.disabledReadAllBtn()
    }
  }
}
