import { application } from "./application"
// import Flatpickr
import Flatpickr from 'stimulus-flatpickr'
// add Flatpickr locales
import "flatpickr/dist/l10n/uk.js"
import "flatpickr/dist/l10n/ru.js"
// Import Flatpickr theme styles
import 'flatpickr/dist/themes/airbnb.css'
// Manually register Flatpickr as a stimulus controller
application.register('flatpickr', Flatpickr);

import FlashController from "./flash_controller.js"
application.register("flash", FlashController)

import MessageController from "./message_controller.js"
application.register("message", MessageController)

import AutosearchController from "./autosearch_controller.js"
application.register("autosearch", AutosearchController)

import SearchController from "./search_controller.js"
application.register("search", SearchController)

import ServiceController from "./service_controller.js"
application.register("service", ServiceController)

import EndlessController from "./endless_controller.js"
application.register("endless", EndlessController)

import CatalogController from "./catalog_controller.js"
application.register("catalog", CatalogController)

import FiltersController from "./filters_controller.js"
application.register("filters", FiltersController)

import OrdersController from "./orders_controller.js"
application.register("orders", OrdersController)

import GeneralCollectionsController from "./general_collection_controller.js"
application.register("general_collection", GeneralCollectionsController)

import CollectionsController from "./collection_controller.js"
application.register("collection", CollectionsController)

import NotificationsController from "./notifications_controller.js"
application.register("notifications", NotificationsController)

import SimilarProductsController from "./similar_products_controller.js"
application.register("similar_products", SimilarProductsController)

import UpdateCartController from "./update_cart_controller.js"
application.register("update-cart", UpdateCartController)

import RecentlyCartController from "./recently_cart_controller.js"
application.register("recently-cart", RecentlyCartController)

import RecentlyProductsController from "./recently_products_controller.js"
application.register("recently-products", RecentlyProductsController)

import SplideController from "./splide/main_controller.js"
application.register("splide", SplideController)

import SplideCartRecentlyController from "./splide/cart_recently_controller.js"
application.register("splide-cart-recently", SplideCartRecentlyController)

import SplideRecentlyProductsController from "./splide/recently_products_controller.js"
application.register("splide-recently-products", SplideRecentlyProductsController)

import RecentlyServicesController from "./recently_services_controller.js"
application.register("recently-services", RecentlyServicesController)

import SplideRecentlyServicesController from "./splide/recently_services_controller.js"
application.register("splide-recently-services", SplideRecentlyServicesController)

import PriceSelectController from "./price_select_controller.js"
application.register("price-select", PriceSelectController)

import AddToCartController from "./add_to_cart_controller.js"
application.register("add-to-cart", AddToCartController)

import QuickOrderController from "./quick_order_controller.js"
application.register("quick-order", QuickOrderController)

import ClearFiltersListController from "./clear_filters_list_controller.js"
application.register("clear-filters-list", ClearFiltersListController)

import CollapseInitialController from "./collapse/initial_controller.js"
application.register("collapse-initial", CollapseInitialController)

import CollapseStateController from "./collapse/state_controller.js"
application.register("collapse-state", CollapseStateController)

import CollapseGenderController from "./collapse/gender_controller.js"
application.register("collapse-gender", CollapseGenderController)

import CollapsePriceController from "./collapse/price_controller.js"
application.register("collapse-price", CollapsePriceController)

import CollapseMakerController from "./collapse/maker_controller.js"
application.register("collapse-maker", CollapseMakerController)

import CollapseCategoryController from "./collapse/category_controller.js"
application.register("collapse-category", CollapseCategoryController)

import TrixToolbarController from "./collapse/trix_toolbar_controller.js"
application.register("trix-toolbar", TrixToolbarController)

import ButtonCollapseController from "./collapse/button_collapse_controller.js"
application.register("button-collapse", ButtonCollapseController)

import ControlButtonController from "./filters/buttons/control_controller.js"
application.register("button-control", ControlButtonController)

import CheckboxSelectController from "./filters/checkbox/checkbox_control_controller.js"
application.register("checkbox-select", CheckboxSelectController)

import ClearUserHistoryController from "./user_history/clear_user_history_controller.js"
application.register("clear-user-history", ClearUserHistoryController)

import ScrollNotificationsController from "./scroll_notifications_controller.js"
application.register("scroll-notifications", ScrollNotificationsController)

import WizardAddressController from "./wizard_address_controller.js"
application.register("wizard-address", WizardAddressController)

import CategorySectionController from "./category_section_controller.js"
application.register("category-section", CategorySectionController)

import MetaTagsController from "./meta_tags_controller.js"
application.register("meta-tags", MetaTagsController)

import ProfinderController from "./profinder_controller.js"
application.register("profinder", ProfinderController)

import PostModalController from "./post_modal_controller.js"
application.register("post-modal", PostModalController)

import CharacterCounter from './character_counter_controller.js'
application.register('character-counter', CharacterCounter)

import PostDeleteController from "./post_delete_controller.js"
application.register("post-delete", PostDeleteController)

import FavoritesController from "./favorites_controller.js"
application.register("favorites", FavoritesController)

import SearchFormController from "./search_form_controller.js"
application.register("search-form", SearchFormController)

import UploadController from "./upload_controller.js"
application.register("upload", UploadController)

import ProductReviewsController from './tabs/product_reviews_controller.js'
application.register('tab-product-reviews', ProductReviewsController)

import FormProductEditController from './forms/product_edit_controller.js'
application.register('form-product-edit', FormProductEditController)

import ChildFieldsEditController from './forms/child_fields_controller.js'
application.register('child-fields', ChildFieldsEditController)

import CityFieldsController from './forms/city_fields_controller.js'
application.register('city-fields', CityFieldsController)

import FormMoreInfoController from './forms/user_more_info_controller.js'
application.register('form-more-info', FormMoreInfoController)

import PostChannelFormController from './forms/post_channel_form_controller.js'
application.register('post-channel-form', PostChannelFormController)

import ActivateButtonController from './forms/activate_button_controller.js'
application.register('activate-button', ActivateButtonController)

import PhoneMaskController from './imask_controller.js'
application.register('imask', PhoneMaskController)

import WizardIdentitiesController from './forms/wizard_identities_controller.js'
application.register('wizard-identities', WizardIdentitiesController)

import QuestionFormController from './forms/question_controller.js'
application.register('question', QuestionFormController)

import TextAreaResizeController from './forms/textarea_resize_controller.js'
application.register('textarea-resize', TextAreaResizeController)

import CitySelectController from './forms/city_select_controller.js'
application.register('city-select', CitySelectController)

import BackgroundProfileEditorController from './forms/bg_profile_editor_controller.js'
application.register('bg-editor', BackgroundProfileEditorController)

import AgeSelectController from './forms/age_select_controller.js'
application.register('age-select', AgeSelectController)

import CategorySelectController from './forms/category_select_controller.js'
application.register('category-select', CategorySelectController)

import ReviewImagesModalController from "./modals/review_images_modal_controller.js"
application.register("review-images-modal", ReviewImagesModalController)

import ModalChannelFilterController from './modals/channel_filter_controller.js'
application.register('modal-channel-filter', ModalChannelFilterController)

import MainModalController from './modals/main_controller.js'
application.register("modal", MainModalController)

import ShowImageModalController from './modals/show_image_controller.js'
application.register("show-image", ShowImageModalController)

import ReasonModalController from './modals/reason_controller.js'
application.register("reason", ReasonModalController)

import ReportFormModalController from './modals/report_form_controller.js'
application.register("report-form", ReportFormModalController)

import AuthModalController from './modals/auth_controller.js'
application.register("auth-modal", AuthModalController)

import DeactivateModalController from './modals/deactivate_controller.js'
application.register("deactivate-modal", DeactivateModalController)

import ShowMoreController from './show_more_controller.js'
application.register("show-more", ShowMoreController)

import ShowMoreRichTextController from './show_more_rich_text_controller.js'
application.register("show-more-rich-text", ShowMoreRichTextController)

import PageTabController from './tabs/page_controller.js'
application.register('main-page-tab', PageTabController)

import GeneralTabController from './tabs/general_tab_controller.js'
application.register('tabs', GeneralTabController)

// universal tabs switcher
import SwitchTabController from './tabs/switch_tab_controller.js'
application.register('switch-tab', SwitchTabController)
// universal modal cleaner
import CropperController from './cropper_js_controller.js'
application.register('cropper', CropperController)

import PageAutosearchController from './page_autosearch_controller.js'
application.register('page-autosearch', PageAutosearchController)

import CallbackController from './components/login/callback_controller.js'
application.register('callback-social-login', CallbackController)

import LoginSocialController from './components/login/social_controller.js'
application.register('login-social', LoginSocialController)

import SessionsPhoneController from './components/login/sessions_phone_controller.js'
application.register('sessions', SessionsPhoneController)

import RegistrationController from './components/login/registration_controller.js'
application.register('registration', RegistrationController)

import PasswordController from './components/login/password_controller.js'
application.register('password', PasswordController)

import OtpController from './components/login/otp_controller.js'
application.register('otp', OtpController)

// offcanvas chat
import ChatlistController from './components/chat/list_controller.js'
application.register('chat-list', ChatlistController)

import ObjectChatController from './components/chat/object_controller.js'
application.register('object-chat', ObjectChatController)

// chat index
import ChatLinkController from './components/chat/link_controller.js'
application.register('chat-link', ChatLinkController)

import ChatFilePreviewController from './components/chat/file_preview_controller.js'
application.register('chat-file-preview', ChatFilePreviewController)

import ChatServiceController from './components/chat/service_controller.js'
application.register('chat-service', ChatServiceController)

import ChatUploadController from './components/chat/upload_controller.js'
application.register('chat-upload', ChatUploadController)

import ChatMoreMessagesController from './components/chat/more_messages_controller.js'
application.register('more-messages', ChatMoreMessagesController)

// emoji
import EmojiPickerController from './emoji_picker_controller.js'
application.register('emoji-picker', EmojiPickerController)

import RightMenuController from './menu/right_menu_controller.js'
application.register('right-menu', RightMenuController)

import RequestFormController from './components/services/request_form_controller.js'
application.register('request-form', RequestFormController)

import OfferFormController from './components/services/offer_form_controller.js'
application.register('offer-form', OfferFormController)

import ServiceFormController from './components/services/service_form_controller.js'
application.register('service-form', ServiceFormController)

import PremiumFormController from './components/services/premium_form_controller.js'
application.register('premium-form', PremiumFormController)

import RedirectController from './service/redirect_controller.js'
application.register('redirect', RedirectController)

import ModalRedirectController from './service/modal_redirect_controller.js'
application.register("modal-redirect", ModalRedirectController)

import RefreshController from './service/refresh_controller.js'
application.register('refresh', RefreshController)

import ReinitTooltipController from './service/reinit_tooltip_controller.js'
application.register('reinit-tooltip', ReinitTooltipController)

import StarRatingController from './service/star_rating_controller.js'
application.register('star-rating', StarRatingController)

import SelectAllController from './service/select_all_controller.js'
application.register('select-all', SelectAllController)

import ProfileController from './profile_controller.js'
application.register('profile', ProfileController)

import ClipboardController from './clipboard_controller.js'
application.register('clipboard', ClipboardController)

import ValidateDiscountsController from './validate_discounts_controller.js'
application.register('validate-discounts', ValidateDiscountsController)

import LiqpayController from './liqpay_controller.js'
application.register('liqpay', LiqpayController)