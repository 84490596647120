import { Controller } from "@hotwired/stimulus"
import { put } from '@rails/request.js'

export default class extends Controller {
  static targets = ["amountInput", "data", "signature", "submitButton"]
  static values = { payChargeId: Number }

  connect() {
    this.originalAmount = this.amountInputTarget.value // Зберігаємо початкову суму
  }

  async handleSubmit(event) {
    event.preventDefault()

    const newAmount = this.amountInputTarget.value.trim()

    if (newAmount !== this.originalAmount) {
      // Показуємо лоадер
      this.showLoader()

      // Відправляємо запит на сервер, якщо сума змінилась
      const response = await put(`/pay_charges/${this.payChargeIdValue}/update_amount`, {
        body: JSON.stringify({ total_amount: newAmount }),
        contentType: 'application/json',
        responseKind: 'json'
      })

      if (response.ok) {
        const { new_data, new_signature } = await response.json

        // Оновлюємо приховані поля
        this.dataTarget.value = new_data
        this.signatureTarget.value = new_signature

        // Приховуємо лоадер
        this.hideLoader()

        // Програмно відправляємо форму після успішного оновлення
        this.element.submit()
      } else {
        alert("Помилка під час оновлення даних для оплати")
        this.hideLoader()
      }
    } else {
      // Якщо сума не змінилася, просто відправляємо форму
      this.element.submit()
    }
  }

  showLoader() {
    const loaderContainer = document.getElementById("turbo-loader-container")
    const loaderBackdrop = document.getElementById("turbo-loader-backdrop")

    // Додаємо текстове повідомлення
    const loaderMessage = document.createElement("p")
    loaderMessage.textContent = "Зачекайте, формуємо дані для оплати..."
    loaderMessage.classList.add("mt-4")
    loaderMessage.style.position = "relative"
    loaderMessage.style.zIndex = "20"
    loaderMessage.id = "loader-message"

    // Відображаємо лоадер
    loaderContainer.classList.remove("d-none")
    loaderBackdrop.classList.remove("d-none")

    // Додаємо повідомлення до лоадера
    loaderContainer.appendChild(loaderMessage)
  }

  hideLoader() {
    const loaderContainer = document.getElementById("turbo-loader-container")
    const loaderBackdrop = document.getElementById("turbo-loader-backdrop")

    // Приховуємо лоадер
    loaderContainer.classList.add("d-none")
    loaderBackdrop.classList.add("d-none")

    // Видаляємо повідомлення
    const loaderMessage = document.getElementById("loader-message")
    if (loaderMessage) {
      loaderContainer.removeChild(loaderMessage)
    }
  }
}
